import { render, staticRenderFns } from "./listReport.vue?vue&type=template&id=06587d03&scoped=true"
import script from "./listReport.vue?vue&type=script&lang=js"
export * from "./listReport.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06587d03",
  null
  
)

export default component.exports