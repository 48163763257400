<template>
  <div>
    <div class="filter-report">
      <template>
        <div>
          <div
              show
              variant="light"
              class="alert alert-custom alert-white alert-shadow fade show gutter-b justify-content-end"
          >
            <b-col md="4">
              <b-container fluid>
                <b-col md="9"><label :for="`date`">Từ ngày:</label></b-col>
                <b-col md="9">
                  <b-form-input
                      :id="`type-date`"
                      :type="`date`"
                      v-model="dateFrom"
                  ></b-form-input>
                </b-col>
              </b-container>
            </b-col>

            <b-col md="4">
              <b-container fluid>
                <b-col md="9"><label :for="`date`">Đến ngày:</label></b-col>
                <b-col md="9">
                  <b-form-input
                      :id="`type-date`"
                      :type="`date`"
                      v-model="dateTo"
                  ></b-form-input>
                </b-col>
              </b-container>
            </b-col>

            <b-col md="4">
              <b-col md="9" class="pt-2"><label ></label></b-col>
              <b-col class="text-right">
                <b-button-group>
                  <b-button type="button" variant="primary" @click="getReport()">
                    <i class="la la-search"></i>
                    <span>Tìm kiếm</span>
                  </b-button>

                  <b-button type="button" variant="success" @click="exportCsv">
                    <i class="la la-file-export"></i>
                    <span>Xuất file</span>
                  </b-button>
                </b-button-group>
              </b-col>
            </b-col>
          </div>

          <!--    <b-card-text v-if="errorMessage" align="center" class="text-danger">{{-->
          <!--      errorMessage-->
          <!--    }}</b-card-text>-->

          <alert-dialogue ref="confirmDialogue"></alert-dialogue>
<!--          <export-modal :modal-id="modalId" @exportData="exportData"></export-modal>-->
        </div>
      </template>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-6">
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">1. Giao dịch của khách hàng cá nhân (không bao gồm ĐVCNTT) có số lượng giao dịch nhiều nhất</h6>
              </template>
              <ListReport :listReport="top_transaction"></ListReport>
            </b-card>
          </b-card-group>
        </div>
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">2. Giao dịch của khách hàng cá nhân (không bao gồm ĐVCNTT) có giá trị giao dịch nhiều nhất</h6>
              </template>
              <ListReport :listReport="top_amount"></ListReport>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListReport from "@/view/pages/Report/top_ten/components/listReport.vue";
const BiRepository = RepositoryFactory.get("Bi");
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
// import ExportModal from "@/view/pages/components/ExportModal";
import AlertDialogue from "@/view/pages/components/AlertModal";
// import router from "@/router";
Vue.component("multiselect", Multiselect);

// const TransactionRepository = RepositoryFactory.get("transactions");
export default {
  mixins: [Common],
  data() {
    return {
      filter: {},
      fields: [
        {
          key: "customer_name",
          label: "Tên khách hàng",
        },
        {
          key: "wallet_name",
          label: "Tên ví/Số hiệu ví",
        },
        {
          key: "cccd",
          label: "Số CMND/CCCD",
        },
        {
          key: "quantity_recharge",
          label: "Số lượng giao dịch",
        },
        {
          key: "amount_recharge",
          label: "Giá trị giao dịch",
        },
        {
          key: "quantity_withdraw",
          label: "Số lượng giao dịch",
        },
        {
          key: "amount_withdraw",
          label: "Giá trị giao dịch",
        },
        {
          key: "quantity_payment",
          label: "Số lượng giao dịch",
        },
        {
          key: "amount_payment",
          label: "Giá trị giao dịch",
        },
        {
          key: "quantity_other",
          label: "Số lượng giao dịch",
        },
        {
          key: "amount_other",
          label: "Giá trị giao dịch",
        },
        {
          key: "total_quantity",
          label: "Số lượng giao dịch",
        },
        {
          key: "total_amount",
          label: "Giá trị giao dịch",
        },
      ],
      top_transaction: {},
      top_amount: {},
      items: [
        {
          customer_name: "Test",
          wallet_name: "Test",
          cccd: "Test",
          quantity_recharge: 1,
          amount_recharge: 500000,
          quantity_withdraw: 1,
          amount_withdraw: 500000,
          quantity_payment: 2,
          amount_payment: 500000,
          quantity_other: 3,
          amount_other: 500000,
          total_quantity: 5,
          total_amount: 500000,
        },
      ],
      items1: [
        {
          customer_name: "Test2",
          wallet_name: "Test2",
          cccd: "Test2",
          quantity_recharge: 1,
          amount_recharge: 500000,
          quantity_withdraw: 1,
          amount_withdraw: 500000,
          quantity_payment: 2,
          amount_payment: 500000,
          quantity_other: 3,
          amount_other: 500000,
          total_quantity: 5,
          total_amount: 500000,
        },
      ],
      dateFrom: this.getFirstDayOfMonth(),
      dateTo: this.getCurrentDay(),
      modalId: "export_report_individual",
    };
  },
  components: {
    ListReport,
    AlertDialogue,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Báo cáo top 10 giao dịch khách hàng cá nhân" },
    ]);
  },
  methods: {
    getReport() {
      this.$bus.$emit("show-loading", true);
      this.data = {};
      this.top_transaction = {};
      this.top_amount = {};
      this.errorMessage = null;
      return BiRepository.getListTopTen({
        date_from: this.dateFrom,
        date_to: this.dateTo,
      })
        .then((response) => {
          if (response.status !== 200 || response.data.error_code === 1) {
            this.notifyAlert("warn", response.data.message);
          }
          this.$bus.$emit("show-loading", false);
          this.data = response.data.data;
          this.top_transaction = response.data.data.top_transaction;
          this.top_amount = response.data.data.top_amount;
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return (this.errorMessage = "Có lỗi khi lấy thông tin");
        });
    },
    async exportCsv() {
      this.$bus.$emit("show-loading", true);
      this.errorMessage = null;
      let self = this;
      return BiRepository.exportTopTen({
        date_from: this.dateFrom,
        date_to: this.dateTo,
      })
        .then(async (response) => {
          if (response.status !== 200 || response.data.error_code === 1) {
            this.notifyAlert("warn", response.data.message);
            this.$bus.$emit("show-loading", false);
            return;
          }
          this.$bus.$emit("show-loading", false);

          let link = response.data.data;
          window.open(link, "_blank");
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return (self.errorMessage = "Có lỗi khi lấy thông tin");
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
input[type="text"],
input[type="date"],
select {
  min-height: 43px;
}
</style>
