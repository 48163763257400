<template>
  <b-table-simple class="table-bordered" responsive="true" hover>
    <b-thead class="thead-default">
      <b-tr>
        <b-th rowspan="2" class="align-middle">STT</b-th>
        <b-th rowspan="2" class="align-middle text-center">Tên khách hàng</b-th>
        <b-th rowspan="2" class="align-middle text-center">Tên ví/Số hiệu ví</b-th>
        <b-th rowspan="2" class="align-middle text-center">Số CMND/CCCD</b-th>
        <b-th colspan="2" class="text-center">Nạp tiền</b-th>
        <b-th colspan="2" class="text-center">Rút tiền</b-th>
        <b-th colspan="2" class="text-center"
        >Thanh toán, chuyển tiền</b-th
        >
        <b-th colspan="2" class="text-center">Khác</b-th>
        <b-th colspan="2" class="text-center">Tổng cộng</b-th>
      </b-tr>
      <b-tr>
        <b-th class="text-center">Số lượng giao dịch</b-th>
        <b-th class="text-center">Giá trị giao dịch</b-th>
        <b-th class="text-center">Số lượng giao dịch</b-th>
        <b-th class="text-center">Giá trị giao dịch</b-th>
        <b-th class="text-center">Số lượng giao dịch</b-th>
        <b-th class="text-center">Giá trị giao dịch</b-th>
        <b-th class="text-center">Số lượng giao dịch</b-th>
        <b-th class="text-center">Giá trị giao dịch</b-th>
        <b-th class="text-center">Số lượng giao dịch</b-th>
        <b-th class="text-center">Giá trị giao dịch</b-th>
      </b-tr>
    </b-thead>
    <b-tbody v-for="(item,index) in listReport" :key="index">
      <b-tr>
        <b-td>{{ index + 1 }}</b-td>
        <b-td>{{ item.user_name }}</b-td>
        <b-td>{{ item.name }}</b-td>
        <b-td>{{ item.id_card_no }}</b-td>
        <b-td>{{ numberFormat(item.c_deposit) }}</b-td>
        <b-td>{{ numberFormat(item.s_deposit) }}</b-td>
        <b-td>{{ numberFormat(item.c_withdraw) }}</b-td>
        <b-td>{{ numberFormat(item.s_withdraw) }}</b-td>
        <b-td>{{ item.c_payment_transfer }}</b-td>
        <b-td>{{ numberFormat(item.s_payment_transfer) }}</b-td>
        <b-td>0</b-td>
        <b-td>0</b-td>
        <b-td>{{ numberFormat(item.transaction) }}</b-td>
        <b-td>{{ numberFormat(item.s_amount) }}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import Common from "@/core/mixins/common";
export default {
  mixins: [Common],
  name: "ListReport",
  props: ["paramFilter", "listReport"],
  data() {
    return {};
  },
};
</script>

<style scoped></style>
